import React, { Component } from "react";
import Parse from "parse";
import { getParameterByName } from "../utils/formatter";

import SearchService from "../components/SearchService";
import NavMenuUser from "./NavMenuUser";
import {Helmet} from "react-helmet";


import "../styles/nav.css";
import logoWolly from "../assets/completo-negro.png"
export default class Nav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fromBusinessId: null,
            fromIntegration: null,
            integrationUrl: null,
            collapsed: false,
            isBusiness: false,
            lockPowered:false
        };
    }

    toggleNav = () => {
        document.body.classList.toggle("no-scroll");
        this.setState(prevState => ({
            collapsed: !prevState.collapsed
        }));
    };

    update = dataFromChild => {
        this.props.update(dataFromChild);
    };

    componentDidMount() {
        const checkBusiness = async () => {
            const businessId = getParameterByName("business");
            try {
                const business = await new Parse.Query("Business").get(
                    businessId
                );
                if (business) {
                    this.setState({
                        isBusiness: true,
                        fromIntegration: business.has("url")
                            ? business.get("Name")
                            : business.get("Name").toLowerCase(),
                        integrationUrl: business.has("Url")
                            ? business.get("Url")
                            :  getParameterByName("business") != "null" ?"#/?business="+ getParameterByName("business"):"#",
                        fromBusinessId: business.has("LandingLogo")
                            ? business.get("LandingLogo").url()
                            : null,
                        businessMetaTitle:business.has("businessMetaTitle")
                            ? business.get("businessMetaTitle")
                            : null,
                        businessMetaDescription:business.has("businessMetaDescription")
                            ? business.get("businessMetaDescription")
                            : null,
                    });
                    if(business.get("Name") == "Create"){
                        this.setState({
                            lockPowered:true,
                        });
                    }
                }
            } catch (e) {}
        };
        checkBusiness();

        switch (getParameterByName("from")) {
            case "badi":
                this.setState({
                    fromIntegration: "badi",
                    integrationUrl: "https://badi.com"
                });
                break;
            case "manomano":
                this.setState({
                    fromIntegration: "manomano",
                    integrationUrl: "https://manomano.es"
                });
                break;
            case "apartmentbarcelona":
                this.setState({
                    fromIntegration: "apartmentbarcelona",
                    integrationUrl: "http://apartmentbarcelona.com"
                });
                break;
            case "smarthouse":
                this.setState({
                    fromIntegration: "smarthouse",
                    integrationUrl: "https://smarthouse.phonehouse.es"
                });
                break;
            case "weguest":
                this.setState({
                    fromIntegration: "weguest",
                    integrationUrl: "https://www.weguest.com"
                });
                break;
            case "solucionaf":
                this.setState({
                    fromIntegration: "solucionaf",
                    integrationUrl: "http://solucionaf.com"
                });
                break;
            case "rentger":
                this.setState({
                    fromIntegration: "rentger",
                    integrationUrl: "https://www.rentger.com/es"
                });
                break;
            case "homming":
                this.setState({
                    fromIntegration: "homming",
                    integrationUrl: "https://www.homming.com"
                });
                break;
            case "mapfre":
                this.setState({
                    fromIntegration: "mapfre",
                    integrationUrl: "https://www.mapfre.es"
                });
                break;
            default:
                this.setState({
                    fromIntegration: null,
                    integrationUrl: null
                });
        }
    }

    render() {
        const {
            fromIntegration,
            integrationUrl,
            collapsed,
            fromBusinessId,
            isBusiness,
            businessMetaTitle,
            businessMetaDescription,
            lockPowered,

        } = this.state;

        return (
            <nav
                id="nav"
                className={
                    collapsed
                        ? "jobin-app-navbar collapsed"
                        : fromIntegration
                        ? "jobin-app-navbar integrations " + fromIntegration
                        : "jobin-app-navbar"
                }
                >


                <Helmet>
                    <meta charSet="utf-8" />
                    <meta content={businessMetaTitle} property="og:title"/>
                    <meta content={businessMetaDescription} property="og:description"/>
                    <title >{businessMetaTitle}</title>
                    <meta content={businessMetaDescription} name="description" />
                </Helmet>


                <div className="navbar-header">
                    {fromIntegration ? (
                        <ul className="logos">
                            <li>
                                <a
                                    id="logo"
                                    href={integrationUrl}
                                    target={ getParameterByName("business") != "null"?  "":"_blank"}
                                    rel="noopener noreferrer"
                                    className={`${fromIntegration} collaborator-logo`}
                                >
                                    <img
                                        alt={`${fromIntegration} logo`}
                                        src={
                                            fromBusinessId !== null
                                                ? fromBusinessId
                                                : require(`../assets/integrations/ic_${fromIntegration}.png`)
                                        }
                                    />
                                </a>
                            </li>
                            <li id="by-jobin-li">
                           {lockPowered ? null:(
                                    <img
                                        src={fromIntegration === 'bauhaus' ? require("../assets/powered-wolly-logo-blanco.png") : require("../assets/powered-wolly-logo.png")}
                                        alt="Jobin logo"
                                        style={{height:120, marginTop: -35}}
                                    />)}
                            </li>
                        </ul>
                    ) : (
                        <a
                            href="https://profesionales.wollyhome.com/profesionales-del-hogar-wolly/"
                            className="navbar-brand"
                            aria-label="Jobin Home"
                        >
                            <img style={{height:120, marginTop: -25}} src={require("../assets/wolly-logo-updated.png")}/>
                        </a>
                    )}
                </div>
                {!isBusiness && (
                    <ul className="navbar-collapse" aria-hidden="false">
                        <li className="left">
                            <ul>
                                {/* <li className="nav-item">
                                    <a href="https://jobin.es/como-funciona">
                                        Cómo funciona
                                    </a>
                                    <svg
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        className="hidden-lg hidden-md hidden-sm"
                                    >
                                        <g
                                            stroke="none"
                                            strokeWidth="1"
                                            fill="none"
                                            fillRule="evenodd"
                                        >
                                            <path
                                                d="M12,5.0001 C11.448,5.0001 11,4.5521 11,4.0001 L11,1.0001 C11,0.4481 11.448,0.0001 12,0.0001 C12.552,0.0001 13,0.4481 13,1.0001 L13,4.0001 C13,4.5521 12.552,5.0001 12,5.0001 Z M4.0029,12.9972 L1.0029,12.9972 C0.4509,12.9972 0.0029,12.5492 0.0029,11.9972 C0.0029,11.4452 0.4509,10.9972 1.0029,10.9972 L4.0029,10.9972 C4.5549,10.9972 5.0029,11.4452 5.0029,11.9972 C5.0029,12.5492 4.5549,12.9972 4.0029,12.9972 Z M22.9971,12.9972 L19.9971,12.9972 C19.4451,12.9972 18.9971,12.5492 18.9971,11.9972 C18.9971,11.4452 19.4451,10.9972 19.9971,10.9972 L22.9971,10.9972 C23.5491,10.9972 23.9971,11.4452 23.9971,11.9972 C23.9971,12.5492 23.5491,12.9972 22.9971,12.9972 Z M7.0522,7.0494 C6.6612,7.4404 6.0282,7.4404 5.6382,7.0494 L3.5162,4.9274 C3.1262,4.5374 3.1262,3.9044 3.5162,3.5134 C3.9072,3.1234 4.5402,3.1234 4.9312,3.5134 L7.0522,5.6354 C7.4432,6.0254 7.4432,6.6584 7.0522,7.0494 Z M16.9478,7.0499 C16.5568,6.6589 16.5568,6.0259 16.9478,5.6349 L19.0698,3.5139 C19.4598,3.1239 20.0928,3.1239 20.4838,3.5139 C20.8738,3.9049 20.8738,4.5379 20.4838,4.9279 L18.3628,7.0499 C17.9718,7.4399 17.3388,7.4399 16.9478,7.0499 Z M12.9998,17.732 C12.9998,16.754 13.4808,15.852 14.3188,15.254 C15.3718,14.503 15.9998,13.287 15.9998,12 C15.9998,9.795 14.2058,8 11.9998,8 C9.7948,8 7.9998,9.795 7.9998,12 C7.9998,13.298 8.6368,14.52 9.7038,15.27 C10.5268,15.848 10.9998,16.722 10.9998,17.667 L10.9998,21 L12.9998,21 L12.9998,17.732 Z M5.9998,12 C5.9998,8.692 8.6918,6 11.9998,6 C15.3088,6 17.9998,8.692 17.9998,12 C17.9998,13.932 17.0578,15.757 15.4798,16.882 C15.1748,17.1 14.9998,17.409 14.9998,17.732 L14.9998,18 L14.9998,20 L14.9998,21 C14.9998,22.103 14.1028,23 12.9998,23 L10.9998,23 C9.8968,23 8.9998,22.103 8.9998,21 L8.9998,20 L8.9998,18 L8.9998,17.667 C8.9998,17.382 8.8378,17.105 8.5538,16.906 C6.9548,15.783 5.9998,13.949 5.9998,12 Z"
                                                fill="#444444"
                                            />
                                        </g>
                                    </svg>
                                </li> */}
                                <li className="nav-item">
                                    <a href="https://profesionales.wollyhome.com/profesionales-del-hogar-wolly/">
                                        Soy profesional
                                    </a>
                                    <svg
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        className="hidden-lg hidden-md hidden-sm"
                                    >
                                        <g
                                            stroke="none"
                                            strokeWidth="1"
                                            fill="none"
                                            fillRule="evenodd"
                                        >
                                            <path
                                                d="M8.5,15 C9.329,15 10,15.671 10,16.5 C10,17.329 9.329,18 8.5,18 C7.671,18 7,17.329 7,16.5 C7,15.671 7.671,15 8.5,15 Z M15.5,15 C16.329,15 17,15.671 17,16.5 C17,17.329 16.329,18 15.5,18 C14.671,18 14,17.329 14,16.5 C14,15.671 14.671,15 15.5,15 Z M19.1001,15 L19.1001,14 L5.1001,14 L5.1001,15 C5.1001,18.9 8.2001,22 12.1001,22 C16.0001,22 19.1001,18.9 19.1001,15 Z M5.0001,10.3 L3.8001,12 L20.1001,12 L19.0001,10.3 L19.0001,10 C19.0001,8 18.3001,5.6 16.4001,4.6 L16.8001,9.7 L14.8001,9.7 L14.2001,2.9 C14.1001,2.4 13.7001,2 13.2001,2 L10.8001,2 C10.3001,2 9.9001,2.4 9.8001,2.9 L9.2001,9.7 L7.2001,9.7 L7.6001,4.6 C5.7001,5.6 5.0001,7.9 5.0001,10 L5.0001,10.3 Z M22.8001,12.4 L22.0001,14 L21.1001,14 L21.1001,15 C21.1001,20 17.1001,24 12.1001,24 C7.1001,24 3.1001,20 3.1001,15 L3.1001,14 L2.0001,14 L1.2001,12.5 L3.0001,9.7 C3.1001,6 5.0001,3.2 7.9001,2.3 C8.2001,1 9.4001,0 10.8001,0 L13.2001,0 C14.6001,0 15.8001,1 16.1001,2.3 C19.0001,3.2 20.9001,6 21.0001,9.7 L22.8001,12.4 Z"
                                                fill="#444444"
                                            />
                                        </g>
                                    </svg>
                                </li>
                            </ul>
                        </li>
                        <li className="right">
                            <ul>
                                <NavMenuUser />
                            </ul>
                        </li>
                    </ul>
                )}
                <button id="toggle-nav" onClick={this.toggleNav}>
                    <svg width="24px" height="24px" viewBox="0 0 24 24">
                        <title>menu</title>
                        <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                        >
                            <path
                                d="M11.9999,15.2422 C11.7439,15.2422 11.4879,15.1442 11.2929,14.9492 L7.0499,10.7072 C6.6599,10.3162 6.6599,9.6832 7.0499,9.2932 C7.4409,8.9022 8.0739,8.9022 8.4639,9.2932 L11.9999,12.8282 L15.5359,9.2932 C15.9259,8.9022 16.5589,8.9022 16.9499,9.2932 C17.3399,9.6832 17.3399,10.3162 16.9499,10.7072 L12.7069,14.9492 C12.5119,15.1442 12.2559,15.2422 11.9999,15.2422"
                                fill="#B0B0B0"
                            />
                        </g>
                    </svg>
                </button>
                <div className="hidden-lg hidden-md hidden-sm">
                    <SearchService
                        history={this.props.history}
                        location={this.props.location}
                        update={this.update}
                        mixpanel={this.props.mixpanel}
                    />
                </div>
            </nav>
        );
    }
}
