import React, { useState, useEffect } from "react";
import Parse from "parse";
import { getParameterByName } from "../utils/formatter";

import "../styles/service-steps.css";

import number1 from "../assets/step-item-1.svg";
import number2 from "../assets/step-item-2.svg";
import number3 from "../assets/step-item-3.svg";
import {selectBusinessTerms} from "../utils/image";

export default function ServiceSteps() {
    const [isBusiness, setIsBusiness] = useState(false);

    useEffect(() => {
        const checkBusiness = async () => {
            const businessId = getParameterByName("business");
            try {
                const business = await new Parse.Query("Business").get(
                    businessId
                );
                if (business) setIsBusiness(business);
            } catch (e) {}
        };
        checkBusiness();
    }, []);

    const checkCoppy = (businessId) => {
        switch (businessId) {
            case "mCdXO7Rjms":
                return(
                    <>
                        <div className="step-item">
                            <p className="title">Servicio realizado por Wolly.</p>
                            <p>Wolly es la plataforma digital integral líder en la búsqueda de los mejores profesionales y en la asistencia de los servicios del hogar</p>
                        </div>
                        <div className="step-item">
                            <p className="title">Selecciona el servicio.</p>
                            <p>Indícanos el tipo de servicio o instalación que necesitas y realiza el pago para que Wolly te envíe uno de sus profesionales cualificados para realizar el servicio.</p>
                        </div>
                       
                    </>
                )
                break;
        
            default:
                return(
                    <>
                        <div className="step-item">
                            <img src={number1} alt="1" />
                            <p className="title">Da de alta incidencia.</p>
                            <p>
                            Da de alta la incidencia con toda la información posible.
                            </p>
                        </div>
                        <div className="step-item">
                            <img src={number2} alt="2" />
                            <p className="title">ACR revisa expediente.</p>
                            <p>
                            ACR revisará la incidencia y se pondrá en contacto contigo.
                            </p>
                        </div>
                        <div className="step-item">
                            <img src={number3} alt="3" />
                            <p className="title">Un profesional solucionará tu incidencia.</p>
                            <p>
                            En caso de que proceda, te asignaremos un profesional para que lleve a cabo la reparación necesaria.
                            </p>
                        </div>
                    </>
                )
                break;
        }              
        
    }

    return (
        <div className="row">
            <div id="service-steps" className="hidden-md hidden-sm hidden-xs">
                {checkCoppy(getParameterByName("business"))}
            </div>
            {/*{selectBusinessTerms(getParameterByName("business"))}*/}
        </div>
    )
}
