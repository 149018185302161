import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";

import Nav from "../components/Nav";
import StepTip from "../components/StepTip";
import StepControlls from "../components/StepControlls";
import BreadCrumb from "../components/BreadCrumb";
import Jobin from "jobin-client";

import "../styles/service-picture.css";

import imgUpload from "../assets/img-upload.svg";
import imgAdd from "../assets/img-add.svg";
import imgRemove from "../assets/img-remove.svg";
import stepIconLeft from "../assets/step-icon-left.svg";
import { getParameterByName } from "../utils/formatter";
import { selectBusinessColor } from "../utils/image";


export default function ServicePicture(props) {
    console.log(props.files)
    const [files, setFiles] = useState(props.files);
    const {
        getRootProps,
        getInputProps,
        open,
        isDragAccept,
        isDragReject,
        isDragActive
    } = useDropzone({
        accept: "image/*",
        multiple: true,
        maxSize: 5120000000,
        disabled: files.length > 3,
        onDrop: acceptedFiles =>
            files.length < 4
                ? setFiles(
                      files.concat(
                          acceptedFiles.map(file =>
                              Object.assign(file, {
                                  preview: URL.createObjectURL(file)
                              })
                          )
                      )
                  )
                : false
    });
    const disabled = () => {
        console.log("asasdasd",files)
        if (files.length > 0)
            return false;
        else return true;
    };

    const removeImg = e => {
        const toDelete = files.splice(e.target.getAttribute("data-id"), 1);
        setFiles(files.filter(n => !toDelete.includes(n)));
    };

    const thumbs = files.map((file, index) => (
        <div key={`${file.name}-${index}`} className="thumb">
            <div className="img-remove">
                <img
                    src={imgRemove}
                    alt="remove"
                    data-id={index}
                    onClick={removeImg}
                />
            </div>
            <img src={file.preview} alt={file.name} />
        </div>
    ));

    const imgPlaceholder = files.length > 0 && files.length < 4 && (
        <div className="img-placeholder" onClick={open}>
            <img src={imgAdd} alt="add" />
        </div>
    );

    const update = childData => {
        props.mixpanel.track("Paso 4 completado");

        // push state to parent to save the content
        props.update(childData);
    };

    useEffect(() => {
        if (!(props.location.state && "inner" in props.location.state))
        Jobin.Client.logOut().then(result => {
           
        });
    }, []);

    return (
        <div
            id="service-picture"
            className={files.length > 0 ? "files-added section" : "section"}
        >
            <Nav />
            <div className="margin-top-72px">
                <BreadCrumb history={props.history} location={props.location} />
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h4 className="title">Añade algunas fotos</h4>
                            <p className="hidden-md hidden-sm hidden-xs">
                                Esto ayudará al profesional a entender mejor lo
                                que necesitas. Si no tienes tiempo, puedes
                                continuar sin subir fotos.
                            </p>
                            <p className="hidden-lg">
                                Añade una o varias imágenes que aporten más
                                información.
                            </p>
                            <div className="content">
                                <div
                                    {...getRootProps({
                                        className: isDragAccept
                                            ? "drop-zone drag-acept"
                                            : isDragReject
                                            ? "drop-zone drag-reject"
                                            : "drop-zone"
                                    })}
                                >
                                    <div className="middle-helper">
                                        <input {...getInputProps()} />
                                        <button 
                                            type="button"
                                            style={{ backgroundColor:selectBusinessColor(getParameterByName("business")) , color:  (getParameterByName("business") == "lMyRIzN79G" || getParameterByName("business") == "ZF5WZAbvQ6" || getParameterByName("business") == "kqJtDU5D9b") ? "white": "black"}}                                            
                                        >
                                            <img
                                                src={imgUpload}
                                                alt="img-upload"
                                            />
                                            Añadir fotos
                                        </button>
                                        {isDragAccept && (
                                            <p>O arrástalas hasta aquí</p>
                                        )}
                                        {isDragReject && (
                                            <p>
                                                Solo puedes subir imágenes de
                                                hasta 5mb cada una
                                            </p>
                                        )}
                                        {!isDragActive && (
                                            <p>O arrástalas hasta aquí</p>
                                        )}
                                    </div>
                                </div>
                                <aside>
                                    {thumbs}
                                    {imgPlaceholder}
                                </aside>
                            </div>
                        </div>
                        <div className="col-md-offset-1 col-md-4">
                            <StepTip
                                iconLeft={stepIconLeft}
                                title="Si se trata de una avería"
                                excerpt="Haz fotos de la zona afectada o del aparato. Si es una zona que no tiene demasiada luz no te olvides de usar el flash."
                            />
                        </div>
                    </div>
                </div>
            </div>
            <StepControlls
                history={props.history}
                location={props.location}
                page={props.step}
                disabled={files.length <= 0}
                next="location"
                nextTrigger={files.length <= 0}
                nextTitle={"Siguiente"}
                prev="description"
                cState={files}
                update={update}
            />
            <img
                src="https://pixel.mathtag.com/event/img?mt_id=1443637&amp;mt_adid=230174&amp;mt_exem=&amp;mt_excl=&amp;v1=&amp;v2=&amp;v3=&amp;s1=&amp;s2=&amp;s3="
                width="1"
                height="1"
            />
        </div>
    );
}
