import React, { useEffect } from "react";

import Nav from "../components/Nav";
import StepTip from "../components/StepTip";
import StepControlls from "../components/StepControlls";

import "../styles/service-summary.css";
import Jobin from "jobin-client";

import stepIconLeft from "../assets/step-icon-left-2.svg";
import stepIconRight from "../assets/step-icon-right-2.svg";
import publishIcon from "../assets/summary-publish.svg";
import likeIcon from "../assets/summary-like.svg";
import payIcon from "../assets/summary-pay.svg";
import { connect } from 'react-redux';
import { getParameterByName } from "../utils/formatter.js";


function ServiceSummary(props) {
    useEffect(() => {
        if (!(props.location.state && "inner" in props.location.state))
        Jobin.Client.logOut().then(result => {
            if (result.status === "ok")  window.location.href =getParameterByName("business") != "null" ?"/?business="+ getParameterByName("business"):"/";
        });
    }, []);


    const update = () => {
        props.update();
        if(props.isPelayo === true){
            localStorage.removeItem('Parse/ExxNZxyGaH3MmDYWIwKmg6okGr3VWySlxp4Dk16P/currentUser');
            window.location.reload();
        }
    };

    return (
        <div id="service-summary" className="section">
            <Nav />
            <div className="margin-top-72px">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h4 className="title">Incidencia reportada.</h4>
                            <p className="excerpt">
                            ACR revisará tu solicitud y muy pronto se pondrán en contacto contigo.
                            </p>
                            
                        </div>
                        <div className="col-md-offset-1 col-md-4">
                            
                        </div>
                    </div>
                </div>
            </div>
            <StepControlls
                history={props.history}
                location={props.location}
                update={update}
                nextTitle="Aceptar"
                next="root"
            />
            <img
                src="https://pixel.mathtag.com/event/img?mt_id=1443644&amp;mt_adid=230174&amp;mt_exem=&amp;mt_excl=&amp;v1=&amp;v2=&amp;v3=&amp;s1=&amp;s2=&amp;s3="
                width="1"
                height="1"
            />
        </div>
    );
}

const mapStateToProps = state => {
    return {
        codeJustNow: state.justNowReducer.justNow,
        isPelayo: state.businessCodeReducer.isPelayo
    }
}

const mapDispatchToProps = dispatch => {
    return {
    
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ServiceSummary);
