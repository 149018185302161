import React, { Component } from "react";
import { Link } from "react-router-dom";
import Parse from "parse";
import { selectBusinessIcon } from "../utils/image"
import { getParameterByName } from "../utils/formatter";



import "../styles/child-service-list.css";

class ChildServiceList extends Component {
    _isMounted = false;
    businessId = getParameterByName("business")


    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            isEmpty: false,
            child: [],
            cubroLoqued: [ "WRm6HqypBI", "BeKSZ0GVLu", "6s2mkf1NZN", "i5tRyg5rPm",
                            "tsGujl7Lnq", "P6GTUvRCTa", "JsCQFxv8qg", "uXiFrMCCfN",
                            "wlYhEvCiBK", "eedA994nWY", "NIIi1HCecg", "QtV7wvrL8K",
                            "tbhXIQe3wG", "ZABEKtOaXA", "rwhJvR2b4H", "Fhs8w7NKnX",
                            "dxLfFuXATQ", "TAuA7p9zVV", "vJFPeuGbZu", "v7HH2WqRiu",
                            "EyJ6FgeDoy", "PTlcHgMVht", "3O0ZAYWWIf", "e0tFg5xK4t", 
                            "BlHbLgkZGd"
                        ],
        };
    }

    componentDidMount() {
        this._isMounted = true;

        const ServiceEmptyObj = Parse.Object.extend("Verticales")
                .createWithoutData(this.props.parent.id);
        let childServicePromise
        if(this.props.checkAllowedBusiness(this.businessId)){

            childServicePromise = new Parse.Query("Verticales")
                .equalTo("Father", ServiceEmptyObj)
                .equalTo("NeedDescription", false)
                .equalTo("Active", true)
                .ascending("Position")
                .find();
        }else{

            childServicePromise = new Parse.Query("Verticales")
                .equalTo("Father", ServiceEmptyObj)
                .equalTo("Active", true)
                .ascending("Position")
                .find();
        }

     
        let codeBussines =  getParameterByName("business")

        childServicePromise.then(
            childService => {
                if(codeBussines == 'HJkv3xWBGh'){
                    childService = childService.filter( service => {
                        return !(this.state.cubroLoqued.some( (value) => value == service.id ))
                    })
                }
                
                if (childService.length > 0) {
                    if (this._isMounted) {
                        // aqui
                        this.setState({
                            isLoaded: true,
                            child: childService
                        });
                        this.props.exist(true);
                    }
                } else {
                    if (this._isMounted) {
                        this.setState({
                            isLoaded: true,
                            isEmpty: true
                        });
                        this.props.exist(false);
                    }
                }
            },
            error => {
                if (this._isMounted) {
                    this.setState({
                        isLoaded: false,
                        error
                    });
                }
            }
        );
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    clickHandler = e => {
        this.props.mixpanel.track("Paso 1 completado");

        this.props.update({
            id: e.target.getAttribute("dataid"),
            type: e.target.getAttribute("datatype")
        });
    }

    render() {
        const { error, isLoaded, isEmpty, child } = this.state;
        return null
        // if (error) {
        //     return <div>Error: {error.message}</div>;
        // } else if (isEmpty) {
        //     return "";
        // } else if (!isLoaded) {
        //     return <div>Cargando...</div>;
        // } else {
        //     return (
        //         <ul className={getParameterByName("business") != "null" ? "child-service-list business":"child-service-list"}>
        //             {/* {child.map(childService => {
        //                 return (
        //                     <li key={childService.id}>
        //                         {getParameterByName("business") != "null" && ( this.props.checkAllowedBusiness(this.businessId) )? (<img className="subVerticalBusiness"
        //                             src={selectBusinessIcon(childService, getParameterByName("business"))}
        //                             alt={childService.get("Type")}
        //                         />):null}
        //                         <Link
        //                             to={{
        //                                 pathname: `/category/${childService.id}`,
        //                                 search: this.props.location.search,
        //                                 state: {
        //                                     inner: true
        //                                 }
        //                             }}
        //                             dataid={this.props.parent.id}
        //                             datatype={this.props.parent.get("Type")}
        //                             onClick={this.clickHandler}
        //                         >
        //                             {childService.get("Type")}
        //                         </Link>
        //                     </li>
        //                 );
        //             })} */}
        //         </ul>
        //     );
        // }
    }
}

export default ChildServiceList;
