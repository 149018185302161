import React, { Component } from "react";

import PhoneModal from "../components/PhoneModal";
import CodeModal from "../components/CodeModal";
import NameModal from "../components/NameModal";
import TermsModal from "../components/TermsModal";
import ErrorModal from "../components/ErrorModal";
import NoJobersModal from "../components/NoJobersModal";
import JobinProModal from "../components/JobinProModal";
import StripePaymentModal from "../components/StripePaymentModal";
import Parse from 'parse';

import { connect } from 'react-redux';

import securePayImg from "../assets/stripeAssets/securePay.png"

import "../styles/sign-in.css";

class SignIn extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: this.props.jobInfo.signIn.visible,
            step: this.props.jobInfo.signIn.step,
            phoneModal: this.props.jobInfo.signIn.phoneModal,
            codeModal: this.props.jobInfo.signIn.codeModal,
            nameModal: this.props.jobInfo.signIn.nameModal
        };
    }

    update = async (childData) => {
        const urlServer = process.env.REACT_APP_URL_SERVER;
        const apiId = process.env.REACT_APP_API_ID;
        const jsKey = process.env.REACT_APP_JS_KEY;
        if (childData.type === "phone") {
            this.setState(prevState => ({
                phoneModal: {
                    ...prevState.phoneModal,
                    areaCode: childData.areaCode,
                    phone: childData.phone,
                    user: childData.user
                }
            }));
        } else if (childData.type === "code") {
            this.setState(prevState => ({
                codeModal: {
                    ...prevState.codeModal,
                    code: childData.code
                }
            }));
        } else {
            let data = {
                userId: this.props.objectId,
                name: childData.name,
                email: childData.email
            }
            Parse.initialize(apiId, jsKey);
            Parse.serverURL = urlServer;
            Parse.User.enableRevocableSession();
            let user = await Parse.Cloud.run('updateUser', data)
            this.setState(prevState => ({
                nameModal: {
                    ...prevState.nameModal,
                    name: childData.name,
                    email: childData.email
                }
            }));
        }

        // push state to parent to save the content
        // this.props.update(childData);
    };

    updateStep = childData => {
        this.setState({
            step: childData
        });
    };

    hideModal = childData => {
        this.props.hideModal(childData);
    };

    componentDidMount() {
        document.querySelector("html").classList.add("noscroll");
        document.body.classList.add("noscroll");
    }

    componentWillUnmount() {
        document.querySelector("html").classList.remove("noscroll");
        document.body.classList.remove("noscroll");
    }

    render() {
        const { step, phoneModal } = this.state;
        return (
            <div className="overlay">
                {step === 1 && (
                    <PhoneModal
                        title="Introduce tu teléfono"
                        excerpt="Introduce tu número de teléfono por si los profesionales necesitan contactar contigo."
                        checkbox="Prefiero que me contacten por chat"
                        placeholder="Número de teléfono"
                        button="Continuar"
                        type="phone"
                        step={step}
                        checkAllowedBusiness={this.props.checkAllowedBusiness}
                        updateStep={this.updateStep}
                        hideModal={this.hideModal}
                        mixpanel={this.props.mixpanel}
                        update={this.update}
                    />
                )}
                {step === 2 && (
                    <CodeModal
                        title="Código de seguridad"
                        excerpt="Introduce el código SMS que te hemos enviado al móvil. Puede tardar unos minutos"
                        button="Continuar"
                        backBtn={true}
                        type="code"
                        step={step}
                        checkAllowedBusiness={this.props.checkAllowedBusiness}
                        updateStep={this.updateStep}
                        history={this.props.history}
                        location={this.props.location}
                        hideModal={this.hideModal}
                        mixpanel={this.props.mixpanel}
                        jobInfo={this.props.jobInfo}
                        user={phoneModal.user}
                        areaCode={phoneModal.areaCode}
                        phone={phoneModal.phone}
                        update={this.update}
                    />
                )}
                {step === 3 && (
                    <NameModal
                        title="¿Cómo te llamamos?"
                        excerpt="Tanto nosotros como nuestros profesionles queremos llamarte por tu nombre."
                        placeholder="Nombre"
                        placeholder2="Correo electrónico"
                        button="Continuar"
                        type="name"
                        step={step}
                        checkAllowedBusiness={this.props.checkAllowedBusiness}
                        updateStep={this.updateStep}
                        hideModal={this.hideModal}
                        update={this.update}
                    />
                )}
                {step === 4 && (
                    <TermsModal
                        title="Antes de que te registres"
                        checkAllowedBusiness={this.props.checkAllowedBusiness}
                        excerpt={
                            <React.Fragment>
                                Queremos construir una comunidad basada en la
                                confianza y el respeto. Para conseguirlo,
                                queremos pedirte que te comprometas con estos
                                valores y aceptes nuestros{" "}
                                <a
                                    href="https://wollyhome.com/terminos-condiciones/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Términos y Condiciones
                                </a>
                                , así como nuestra{" "}
                                <a
                                    href="https://profesionales.wollyhome.com/politicaprivacidad/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Política de privacidad
                                </a>
                                .
                            </React.Fragment>
                        }
                        button="Aceptar"
                        type="terms"
                        step={step}
                        updateStep={this.updateStep}
                        history={this.props.history}
                        location={this.props.location}
                        hideModal={this.hideModal}
                        mixpanel={this.props.mixpanel}
                        jobInfo={this.props.jobInfo}
                        user={this.state.phoneModal.user}
                        phone={this.state.phoneModal.phone}
                        areaCode={this.state.phoneModal.areaCode}
                        name={this.state.nameModal.name}
                        email={this.state.nameModal.email}
                        update={this.update}
                    />
                )}
                {step === 5 && (
                    <ErrorModal
                        title="Ups! Algo ha salido mal"
                        excerpt="Prueba de nuevo o llámanos al (+34) 648097254 si necesitas que te echemos una mano."
                        button="Reintentar"
                        type="error"
                        step={step}
                        jobInfo={this.props.jobInfo}
                        updateStep={this.updateStep}
                        history={this.props.history}
                        location={this.props.location}
                        hideModal={this.hideModal}
                        mixpanel={this.props.mixpanel}
                        phone={this.state.phoneModal.phone}
                        areaCode={this.state.phoneModal.areaCode}
                        name={this.state.nameModal.name}
                        email={this.state.nameModal.email}
                        update={this.update}
                        jobTypeState={this.props.jobTypeState}
                    />
                )}
                {step === 6 && (
                    <NoJobersModal
                        title="¡Vaya! No hay Profesionales disponibles en este momento"
                        excerpt="Estamos trabajando para aumentar el número de profesionales en tu zona."
                        button="Aceptar"
                        type="noJobers"
                        step={step}
                        history={this.props.history}
                        location={this.props.location}
                        hideModal={this.hideModal}

                    />
                )}
                {step === 7 && (
                    <JobinProModal
                        title="¡Ups! Este teléfono está asociado a otra cuenta"
                        excerpt="Alguien más está usando este mismo número de teléfono para una cuenta de Wolly. Prueba con tro número o llámanos al 648097254 si necesitas más información."
                        button="Aceptar"
                        type="jobinPro"
                        step={step}
                        history={this.props.history}
                        location={this.props.location}
                    />
                )}
                {step === 8 && (
                    <StripePaymentModal
                        title="Realizar pago"
                        excerpt1="Para poder gestionar el servicio es necesario realizar el pago. Te redirigiremos a un enlace de pago seguro."
                        excerpt2="Una vez finalizado el pago, iniciaremos la tramitación y la asignación del profesional que mejor encaje con tu servicio."
                        excerpt3="Clicando en continuar acepta nuestros "
                        linkConditions="https://uploads-ssl.webflow.com/64e325f959e4274d1ea0299f/65d32f5c9789437da39990b1_Condiciones Generales Instalación Wolly-Clientes.pdf"
                        textConditions="términos y condiciones generales"
                        button="Continuar"
                        type="jobinPro"
                        jobInfo={this.props.jobInfo}
                        step={step}
                        checkAllowedBusiness={this.props.checkAllowedBusiness}
                        updateStep={this.updateStep}
                        history={this.props.history}
                        location={this.props.location}
                        hideModal={this.hideModal}
                        mixpanel={this.props.mixpanel}
                        user={phoneModal.user}
                        areaCode={phoneModal.areaCode}
                        phone={phoneModal.phone}
                        update={this.update}

                    >
                        <p>una vez finalizado el pago, publicaremos tu servicio. En caso de no conseguir ningún profesional te devolveremos el dinero.</p>
                        <p>Consulta aqui todas nuestras condiciones generales. <b>Condiciones generales</b></p>
                        <img src={securePayImg}></img>
                    </StripePaymentModal>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        phoneNumber: state.userReducer.user.phone,
        objectId: state.userReducer.user.objectId,
        hasEmail: state.userReducer.user.hasEmail,
        jobTypeState: state.serviceReducer.service.jobType,
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);