import React, { Component } from "react";
import Lottie from "react-lottie";

import "../styles/step-controlls.css";
// import * as lineSpinnerData from "../animations/line-spinner-white.json";
import * as pendulumSpinnerData from "../animations/pendulum-spinner.json";
import { typeOrder, typeOrderSelected } from '../store/actions/serviceAction';
import back from "../assets/back-step.svg";
import { connect } from 'react-redux';
import { setNullCodeJustNow } from '../store/actions/justNowActions';
import { getParameterByName } from "../utils/formatter";
import {selectBusinessColor, selectBusinessTerms} from "../utils/image";

class StepControlls extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: this.props.page ? this.props.page : 0,
            isLastPage: this.props.isLastPage ? this.props.isLastPage : false,
            disabled: this.props.disabled ? this.props.disabled : false,
            next: this.props.next ? this.props.next : false,
            prev: this.props.prev ? this.props.prev : false,
            nextHidden: this.props.nextHidden ? this.props.nextHidden : false,
            nextTrigger: this.props.nextTrigger
                ? this.props.nextTrigger
                : false,
            nextTitle: this.props.nextTitle
                ? this.props.nextTitle
                : "Siguiente",
            loading: this.props.loading ? this.props.loading : false
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.nextTrigger !== state.nextTrigger) {
            return {
                nextTrigger: props.nextTrigger
            };
        }
        if (props.nextTitle !== state.nextTitle) {
            return {
                nextTitle: props.nextTitle
            };
        }

        return null;
    }

    componentDidUpdate(prevProps) {
        if (this.props.nextTrigger !== prevProps.nextTrigger) {
            if (this.props.nextTrigger) this.disableButton();
            else this.enableButton();
        }

        if (this.props.loadTrigger !== prevProps.loadTrigger)
            this.loadingButton();

        if (this.props.nextTitle !== prevProps.nextTitle)
            this.updateText("next", "Siguiente");
    }

    enableButton = () => {
        this.setState({
            disabled: false
        });
    };

    disableButton = () => {
        this.setState({
            disabled: true
        });
    };

    loadingButton = () => {
        this.setState(prevState => ({
            loading: !prevState.loading,
            disabled: !prevState.disabled
        }));
    };

    updateText = (button, text) => {
        if (button === "next") this.setState({ nextTitle: text });
    };

    next = () => {
        this.setState({
            page: this.state.page + 1
        });
        if(this.props.isMutua === true && this.props.serviceSelected ){
            if (this.state.next === "root") {
                this.props.update();
                this.props.history.push({
                    pathname: "/",
                    search: this.props.location.search,
                    state: {
                        inner: true
                    }
                });
            } else {
                this.props.history.push({
                    pathname: `/${this.props.serviceType}`,
                    search: this.props.location.search,
                    state: {
                        inner: true
                    }
                });
            }
        } else {
            if (this.state.next === "root") {
                this.props.update();
                this.props.history.push({
                    pathname: "/",
                    search: this.props.location.search,
                    state: {
                        inner: true
                    }
                });
            } else {
                this.props.history.push({
                    pathname: `/${this.state.next}`,
                    search: this.props.location.search,
                    state: {
                        inner: true
                    }
                });
            }
        }

        // save content and push it to parent
        if (this.props.update) this.props.update(this.props.cState);
        this.props.typeOrderSelected(false);
    };

    previous = () => {
        this.setState({
            page: this.state.page - 1
        });

        if (this.state.prev === "root") {
            this.props.history.goBack();
            return;
        }

        if (this.props.location.pathname !== "/description") {
            this.props.history.push({
                pathname: `/${this.state.prev}`,
                search: this.props.location.search,
                state: {
                    inner: true
                }
            });
        } else if (
            this.props.location.state &&
            "param" in this.props.location.state
        ) {
            this.props.history.push({
                pathname: `/${this.state.prev}`,
                search: this.props.location.search,
                state: {
                    inner: true
                }
            });
        } else this.props.history.goBack();

        // save content and push it to parent
        if (this.props.update) this.props.update(this.props.cState);
    };

    submit = () => {
        // save content and push it to parent
        if (this.props.update){
            this.props.update(this.props.cState);
        }

        if (this.props.showModal){
            this.props.showModal(true);
        }
    };

    render() {
        const {
            isLastPage,
            disabled,
            nextHidden,
            nextTitle,
            loading
        } = this.state;

        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: pendulumSpinnerData.default,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        };

        return (
            <footer id="footer">
                <div className="bottom-controls">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="controls-content">
                                    <div className="row">
                                        <div className="col-xs-3">
                                            <div className="btn-back">
                                                <button
                                                    type="button"
                                                    className="secondary"
                                                    onClick={this.previous}
                                                >
                                                    <img
                                                        src={back}
                                                        alt="Volver"
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-xs-9">
                                            {!nextHidden &&
                                                (!isLastPage ? (
                                                    <div className="btn-next">
                                                        <button
                                                            style={{ backgroundColor:selectBusinessColor(getParameterByName("business")) , color:  (getParameterByName("business") == "lMyRIzN79G" || getParameterByName("business") == "ZF5WZAbvQ6" || getParameterByName("business") == "kqJtDU5D9b") ? "white": "black"}}
                                                            type="button"
                                                            onClick={this.next}
                                                            disabled={disabled}
                                                        >
                                                            {nextTitle}
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <div className="btn-submit">
                                                        <button
                                                            style={{ backgroundColor:selectBusinessColor(getParameterByName("business")) , color:  (getParameterByName("business") == "lMyRIzN79G" || getParameterByName("business") == "ZF5WZAbvQ6" || getParameterByName("business") == "kqJtDU5D9b") ? "white": "black"}}
                                                            type="submit"
                                                            onClick={
                                                                this.submit
                                                            }
                                                            disabled={disabled}
                                                        >
                                                            {loading ? (
                                                                <Lottie
                                                                    options={
                                                                        defaultOptions
                                                                    }
                                                                    width={48}
                                                                    height={24}
                                                                />
                                                            ) : (
                                                                "Publicar solicitud"
                                                            )}
                                                        </button>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {selectBusinessTerms(getParameterByName("business"))}
                    </div>
                </div>
            </footer>
        );
    }
}

const mapStateToProps = state => {
    return {
        codeJustNow: state.justNowReducer.justNow,
        isPelayo: state.businessCodeReducer.isPelayo,
        customerName: state.userReducer.user.name,
        customerPhone: state.userReducer.user.phone,
        isMutua: state.businessCodeReducer.isMutua,
        serviceType: state.serviceReducer.service.type,
        serviceSelected: state.serviceReducer.service.typeSelected
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setNullCodeJustNow: () => dispatch(setNullCodeJustNow()),
        typeOrder: (type) => dispatch(typeOrder(type)),
        typeOrderSelected: (selected) => dispatch(typeOrderSelected(selected))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (StepControlls);