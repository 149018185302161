import React, { Component } from "react";
import Parse from "parse";
import Jobin from "jobin-client";
import { HashRouter as Router, Route } from "react-router-dom";
import mixpanel, { mixpanelInit } from "./api/mixpanel.js";
import { MixpanelProvider, MixpanelConsumer } from "react-mixpanel";
import { loadReCaptcha } from 'react-recaptcha-v3'

import ConsentPopup from "./components/ConsentPopup";
import ChatbasePopup from "./components/ChatbasePopup";
import ServiceType from "./organisms/ServiceType";
import ServiceCategory from "./organisms/ServiceCategory";
import ServiceDescription from "./organisms/ServiceDescription";
import ServicePicture from "./organisms/ServicePicture";
import ServicePrice from "./organisms/ServicePrice";
import ServiceLocation from "./organisms/ServiceLocation";
import ServiceUrgency from "./organisms/ServiceUrgency";
import SignIn from "./organisms/SignIn";
import TermsContainer from "./organisms/TermsContainer";
import ServiceSummary from "./organisms/ServiceSummary";
import ServiceAvailability from "./organisms/ServiceAvailability.js";
import { connect } from 'react-redux';
import "./styles/grid12.css";
import "./styles/normalize.css";
import "./styles/jobin.css";
import { getURLParameterByName } from "./utils/formatter.js";

const urlServer = process.env.REACT_APP_URL_SERVER;
const apiId = process.env.REACT_APP_API_ID;
const jsKey = process.env.REACT_APP_JS_KEY;
const recaptchaToken = process.env.REACT_APP_RECAPTCHA_TOKEN;
const allowedBusiness = process.env.REACT_APP_ALLOWED_BUSINESS.split(",");

const initialState = {
    currentPage: 0,
    multiplier: 1,
    currentUser: "",
    step: 1,
    category: {
        step: 1,
        categoryId: "",
        categoryType: "",
        subCategoryId: ""
    },
    description: {
        step: 2,
        message: "",
        minChar: 40,
        maxChar: 1000,
        tooltip: "Faltan 40 caracteres",
        styleType: "primary",
        error: null,
        disabledBtn: true,
        middleCategory: ""
    },
    picture: {
        step: 3,
        files: [],
        textBtn: "Continuar sin fotos"
    },
    locate: {
        step: 4,
        latLng: null,
        mapsKey: "AIzaSyDYwng9doYyVkIIyzYLX6crFuJLii07Cus",
        center: {
            lat: 40.416782,
            lng: -3.703391
        },
        zoom: 12,
        address: "",
        addressSelected: null,
        error: null,
        disabledBtn: true,
        promotion_data:[],
        assetSelected:null
    },
    urgency: {
        step: 4,
        priority: null,
        selectedOption: null,
        error: null,
        disabledBtn: true
    },
    availability: {
        dateTo: null,
        error: null,
        disabledBtn: false,
        buttonTitle: "Continuar sin fecha"
    },
    signIn: {
        visible: false,
        step: 1,
        phoneModal: {
            areaCode: "+34",
            phone: "",
            contactMode: "chat",
            disabledBtn: false
        },
        codeModal: {
            code: "",
            disabledBtn: true
        },
        nameModal: {
            name: "",
            email: "",
            disabledBtn: false
        }
    },
    terms: {
        visible: false
    },
    business: {
        name: "",
        id: null
    },
    isAllowedBusiness: false
};

class App extends Component {
    constructor(props) {
        super(props);

        this.state = initialState;
        const businessId = getURLParameterByName("business");

        mixpanelInit();


        try {
            loadReCaptcha(recaptchaToken);
        } catch (error) {
            console.log('no load ReCaptcha')
        }

        Jobin.initServer(apiId, jsKey, urlServer);

        //Solo en Bauhaus se fuerza a que no se puedan cargar por URL otros Business
        if ("ZF5WZAbvQ6" === process.env.REACT_APP_BUSINESS || "kqJtDU5D9b" === process.env.REACT_APP_BUSINESS) {
            localStorage.setItem("business", process.env.REACT_APP_BUSINESS);
        } else {
            localStorage.setItem("business", businessId);
        }

    }
    checkAllowedBusiness = businessId => {
        this.setState({
            isAllowedBusiness: allowedBusiness.includes(businessId)
        })
        return allowedBusiness.includes(businessId)
    }
    updateType = childData => {
        this.setState(prevState => ({
            category: {
                ...prevState.category,
                categoryId: childData.id,
                categoryType: childData.type,
                subCategoryId: ""
            }
        }));
    };

    updateCategory = childData => {
        this.setState(prevState => ({
            category: {
                ...prevState.category,
                categoryId: childData.id
                    ? childData.id
                    : prevState.category.categoryId,
                categoryType: childData.type
                    ? childData.type
                    : prevState.category.categoryType,
                subCategoryId: childData.sub
                    ? childData.sub
                    : prevState.category.subCategoryId
            }
        }));
    };

    updateMiddleCategory = childData => {
        this.setState(prevState => ({
            description: {
                ...prevState.description,
                middleCategory: childData
            }
        }));
    };

    updateAvailability = childData => {
        this.setState({ availability: childData });
    };
    updateMultiplier = childData => {
        this.setState({ multiplier: childData });
    };
    updateDescription = childData => {
        this.setState({ description: childData });
    };

    updatePicture = childData => {
        this.setState(prevState => ({
            picture: {
                ...prevState.picture,
                files: childData
            }
        }));
    };

    updateLocation = childData => {
        this.setState({ locate: childData });
    };

    updateUrgency = childData => {
        this.setState({ urgency: childData });
    };

    resetApp = () => {
        this.setState(initialState);
        this.fetchCurrentUser();
    };

    showModal = childData => {
        this.setState(prevState => ({
            signIn: {
                ...prevState.signIn,
                step: childData.step,
                visible: true
            }
        }));
    };

    hideModal = () => {
        this.setState(prevState => ({
            signIn: {
                ...prevState.signIn,
                visible: false
            }
        }));
    };

    showTermsModal = () => {
        this.setState({
            terms: {
                visible: true
            }
        });
    };

    hideTermsModal = () => {
        this.setState({
            terms: {
                visible: false
            }
        });
    };

    fetchCurrentUser = () => {
        Parse.User.currentAsync().then(
            user => {
                if (user) {
                    this.setState({
                        currentUser: user
                    });
                    Jobin.Client.needToUpdateTerms().then(result => {
                        if (result.status === "error") {
                            Parse.User.logOut();
                            window.location.reload();
                        } else if (result.result) this.showTermsModal();
                    });
                } else {
                    this.setState({
                        currentUser: ""
                    });
                }
            },
            () => {
                this.setState({
                    currentUser: ""
                });
            }
        );
    };
    fetchPromoData = (businessId = false) => {
        if (businessId != "null" && businessId != false) {
            Parse.initialize(apiId, jsKey);
            Parse.serverURL = urlServer;
            Parse.User.enableUnsafeCurrentUser();

            let data = {
                businessId: businessId,
            }
            
            

            let promoData =  Parse.Cloud.run("findAssetByBusiness", data);
            Promise.all([promoData]).then(([result]) => {
                result = result.buildings.map((building)=>{
                        return {
                            ...building,
                            "formattedData": `${building.publicWayType}  ${building.publicWayName} ${building.publicWayNumber}, ${building.postalCode}, ${building.municipality}, ${building.province}`,
                            "assets": building.assets.map((asset)=>{
                                return{
                                    ...asset,
                                    "formattedData": `${asset.stairs? `Escalera: ${asset.stairs};`:""} ${asset.floorNumber? `Piso: ${asset.floorNumber};`:""} ${asset.door? `Puerta: ${asset.door};`:""} ${asset.block? `Bloque: ${asset.block}`:""}`.trim().replace(/;$/,"")
                                }
                            })
                        }
                    })
                    this.setState({
                        locate: {
                            ...this.state.locate,
                            promotion_data:result
                        }
                    })
            })
            .catch((error)=>{
                console.error(error)
            })
        }
    };
    fetchBusinessData = (businessId = false) => {
        if (businessId != "null" && businessId != false && businessId != null) {
            let Businesss = Jobin.Business.getById(businessId)
            Promise.all([Businesss]).then(([result,state]) => {
                if (state != "error") {
                    let business = result.result
                    this.setState({
                        business: {
                            name: business.has("url")
                                ? business.get("Name")
                                : business.get("Name").toLowerCase(),
                            id: business.id
                        }
                    })
                }
            })
        }
    };

    componentDidMount() {
        const businessId = getURLParameterByName("business");
        this.fetchCurrentUser();
        this.fetchBusinessData(businessId)
        this.fetchPromoData(businessId)
    }


    render() {
        const {
            currentUser,
            description,
            picture,
            locate,
            urgency,
            signIn,
            availability,
            terms,
            business
        } = this.state;

        return (
            <div className={business.name}>
                {terms.visible && (
                    <TermsContainer hideTermsModal={this.hideTermsModal} />
                )}
                <MixpanelProvider mixpanel={mixpanel()}>
                    <Router>
                        <Route
                            path="/"
                            exact={true}
                            render={({ history, location }) => (
                                <MixpanelConsumer>
                                    {mixpanel => (
                                        <ServiceType
                                            history={history}
                                            checkAllowedBusiness={this.checkAllowedBusiness}
                                            fetchBusinessData={this.fetchBusinessData}
                                            location={location}
                                            update={this.updateType}
                                            mixpanel={mixpanel}
                                        />
                                    )}
                                </MixpanelConsumer>
                            )}
                        />
                        <Route
                            path="/category/:subCategoryId"
                            render={({ history, location, match }) => (
                                <MixpanelConsumer>
                                    {mixpanel => (
                                        <ServiceCategory
                                            history={history}
                                            location={location}
                                            match={match}
                                            checkAllowedBusiness={this.checkAllowedBusiness}
                                            fetchBusinessData={this.fetchBusinessData}
                                            update={this.updateCategory}
                                            updateMiddleCategory={
                                                this.updateMiddleCategory
                                            }
                                            mixpanel={mixpanel}
                                        />
                                    )}
                                </MixpanelConsumer>
                            )}
                        />
                        <Route
                            path="/description"
                            render={({ history, location }) => (
                                <MixpanelConsumer>
                                    {mixpanel => (
                                        <ServiceDescription
                                            history={history}
                                            location={location}
                                            checkAllowedBusiness={this.checkAllowedBusiness}
                                            fetchBusinessData={this.fetchBusinessData}
                                            {...description}
                                            update={this.updateDescription}
                                            mixpanel={mixpanel}
                                        />
                                    )}
                                </MixpanelConsumer>
                            )}
                        />
                        <Route
                            path="/picture"
                            render={({ history, location }) => (
                                <MixpanelConsumer>
                                    {mixpanel => (
                                        <ServicePicture
                                            history={history}
                                            location={location}
                                            {...picture}
                                            update={this.updatePicture}
                                            mixpanel={mixpanel}
                                            checkAllowedBusiness={this.checkAllowedBusiness}
                                            fetchBusinessData={this.fetchBusinessData}
                                        />
                                    )}
                                </MixpanelConsumer>
                            )}
                        />
                        <Route
                            path="/location"
                            render={({ history, location }) => (
                                <MixpanelConsumer>
                                    {mixpanel => (
                                        <ServiceLocation
                                            history={history}
                                            location={location}
                                            checkAllowedBusiness={this.checkAllowedBusiness}
                                            fetchBusinessData={this.fetchBusinessData}
                                            {...locate}
                                            update={this.updateLocation}
                                            job={this.state}
                                            mixpanel={mixpanel}
                                        />
                                    )}
                                </MixpanelConsumer>
                            )}
                        />
                        <Route
                            path="/urgency"
                            render={({ history, location }) => (
                                <MixpanelConsumer>
                                    {mixpanel => (
                                        <React.Fragment>
                                            <ServiceUrgency
                                                history={history}
                                                location={location}
                                                {...urgency}
                                                update={this.updateUrgency}
                                                showModal={this.showModal}
                                                jobInfo={this.state}
                                                checkAllowedBusiness={this.checkAllowedBusiness}
                                                fetchBusinessData={this.fetchBusinessData}
                                                mixpanel={mixpanel}
                                                currentUser={currentUser}
                                            />
                                            {signIn.visible && (
                                                <SignIn
                                                    step={this.state.step}
                                                    history={history}
                                                    location={location}
                                                    jobInfo={this.state}
                                                    hideModal={this.hideModal}
                                                    checkAllowedBusiness={this.checkAllowedBusiness}
                                                    ss={"ss"}

                                                    mixpanel={mixpanel}
                                                />
                                            )}
                                        </React.Fragment>
                                    )}
                                </MixpanelConsumer>
                            )}
                        />
                        <Route
                            path="/price"
                            render={({ history, location }) => (
                                <MixpanelConsumer>
                                    {mixpanel => (
                                        <ServicePrice
                                            history={history}
                                            location={location}
                                            {...locate}
                                            mixpanel={mixpanel}
                                            checkAllowedBusiness={this.checkAllowedBusiness}
                                            fetchBusinessData={this.fetchBusinessData}
                                            jobInfo={this.state}
                                            updateMultiplier={this.updateMultiplier}
                                        />
                                    )}
                                </MixpanelConsumer>
                            )}
                        />
                        <Route
                            path="/job/new/availability"
                            render={({ history, location }) => (
                                <MixpanelConsumer>
                                    {mixpanel => (
                                        <React.Fragment>
                                            <ServiceAvailability
                                                history={history}
                                                showModal={this.showModal}
                                                location={location}
                                                {...availability}
                                                mixpanel={mixpanel}
                                                checkAllowedBusiness={this.checkAllowedBusiness}
                                                fetchBusinessData={this.fetchBusinessData}
                                                update={this.updateAvailability}
                                                currentUser={currentUser}
                                                jobInfo={this.state}
                                            />
                                            {signIn.visible && (
                                                <SignIn
                                                    step={this.state.step}
                                                    history={history}
                                                    location={location}
                                                    jobInfo={this.state}
                                                    hideModal={this.hideModal}
                                                    checkAllowedBusiness={this.checkAllowedBusiness}

                                                    mixpanel={mixpanel}
                                                />
                                            )}
                                        </React.Fragment>
                                    )}
                                </MixpanelConsumer>
                            )}
                        />
                        <Route
                            path="/summary"
                            render={({ history, location }) => (
                                <ServiceSummary
                                    history={history}
                                    location={location}
                                    update={this.resetApp}
                                />
                            )}
                        />
                        <Route
                            path="/job/new/summary"
                            render={({ history, location }) => (
                                <ServiceSummary
                                    history={history}
                                    location={location}
                                    update={this.resetApp}
                                />
                            )}
                        />
                    </Router>
                    <ConsentPopup />
                    <ChatbasePopup />
                </MixpanelProvider>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)