import React, { useState, useEffect } from "react";
//import { useStateValue } from "../../state/context";
import { selectService, selectLead } from "../components/constants/selectService";
import Jobin from "jobin-client";
import Parse from "parse";
import { Input, RadioButton } from "jobin-ui-components";
//import ExtrasModal from "../modal/ExtrasModal";
//import Button from "../button/Button";
import ButtonEmpty from "../components/button/ButtonEmpty";
import StepControlls from "../components/StepControlls";
//import BreadCrumb from "./generic/BreadCrumb";
import ExtraInfoCell from "../components/detail/ExtraInfoCell";
import { connect } from 'react-redux';
import Nav from '../components/Nav';
import { getParameterByName } from "../utils/formatter";

import "../styles/service-price.css";
import { jobPriceExtra, jobPriceSelected } from '../store/actions/jobAction';
import { typeOrder, typeOrderSelected, jobType } from '../store/actions/serviceAction';
import {selectBusinessColor} from "../utils/image";

function ServicePrice(props) {
    const job = props.job
    const jobInfo = props.jobInfo
    const [tempPrice, setTempPrice] = useState(null);
    const [showLeadOptions, setShowLeadOptions] = useState(false);
    const [tempPriceIVA, setTempPriceIVA] = useState(null);
    const [price, setPrice] = useState(props.price);
    const [priceOk, setPriceOk] = useState(true);
    const [extrasArray, setExtrasArray] = useState(job.price.extrasArray);
    const [showExtrasModal, setShowExtrasModal] = useState(false);
    const [editPrice, setEditPrice] = useState(false);
    const [subvertical, setSubvertical] = useState(undefined);
    const [includes, setIncludes] = useState([]);
    const [doesntInclude, setDoesntInclude] = useState([]);
    const [selectedOption, setSelectedOption] = useState(
        job.price.selectedOption
    );
    const [isSelected, setIsSelected] = useState(false)
    const [typeOrder, setTypeOrder] = useState("");
    const [haveDiscount, setHaveDiscount] = useState(false);
    const [multiplier, setMultiplier] = useState(1);
    const [allowedBusiness, setAllowedBusiness] = useState(false);

    const style = {
        priceContainer: {
            display: "flex",
            flexDirection: "column",
            marginTop: 24,
            marginBottom: 24
        }
    };

    
    useEffect(() => {
        if (!(props.location.state && "inner" in props.location.state)){
            Jobin.Client.logOut().then();
        }
        const getEmptyJob = async () => {
            const { status, result } = await Jobin.Guild.get(
                jobInfo.category.subCategoryId || jobInfo.category.categoryId
            );
            if (status === "ok") {
                setSubvertical(result);
                if (result.has("Includes")) setIncludes(result.get("Includes"));
                if (result.has("DoesntInclude"))
                    setDoesntInclude(result.get("DoesntInclude"));
            }
        };
        const getDiscountData = async (businessId)=>{
            const Business = Parse.Object.extend("Business")
            let discount = await new Parse.Query("Discount").equalTo("Active",true).equalTo("Business",Business.createWithoutData(businessId)).first();
            if(discount && businessId){
                setHaveDiscount(discount.get("percentage"))
            }
        }

        if (!job.transport) getEmptyJob();
        if(getParameterByName("business") != "null" &&  getParameterByName("business")){
            getDiscountData(getParameterByName("business"))
        }
            setAllowedBusiness(true)
            changeOption("fixed")
        
    }, []);

    useEffect(() => {
        if(props.price == 0) setShowLeadOptions(true)
        else setShowLeadOptions(false)
    }, [props.price]);

    const saveExtras = async extraArr => {
        setShowExtrasModal(false);
        setExtrasArray(extraArr);

        const extraPrice = extraArr.reduce((accumulator, extra) => {
            return accumulator + extra.pricePerUnit * extra.units;
        }, 0);

        /*dispatch({
            type: "JOB_PRICE_EXTRAS",
            extrasArray: extraArr,
            extraAmount: extraPrice
        });*/
    };

    const disabled = () => {
        if (job.price.amount + job.price.extraAmount > 0 || job.transport)
            return false;
        else if (selectedOption === "lead") return false;
        else return true;
    };

    const update = () => {
        props.jobPriceSelected(selectedOption);
        props.updateMultiplier(multiplier)
        /*dispatch({
            type: "JOB_PRICE_SELECTED",
            selectedOption: selectedOption
        });*/
    };

    const checkPrice = input => {
        if (input.length > 0) {
            const reg = new RegExp("^-?\\d+\\.\\d{2,2}$");
            if (reg.test(input)) {
                const temp = Number(input).toFixed(3);
                setTempPrice(temp);
                setTempPriceIVA((temp * 1.21).toFixed(2));
                setPriceOk(true);
            } else {
                setTempPrice(null);
                setTempPriceIVA(null);
                setPriceOk(false);
            }
        } else {
            setTempPrice(null);
            setTempPriceIVA(null);
            setPriceOk(true);
        }
    };

    const checkPriceIVA = input => {
        if (input.length > 0) {
            var reg = new RegExp("^-?\\d+\\.\\d{2,2}$");
            if (reg.test(input)) {
                const temp = (Number(input) / 1.21).toFixed(3);
                setTempPrice(temp);
                setTempPriceIVA(Number(input).toFixed(2));
                setPriceOk(true);
            } else {
                setTempPrice(null);
                setTempPriceIVA(null);
                setPriceOk(false);
            }
        } else {
            setTempPrice(null);
            setTempPriceIVA(null);
            setPriceOk(true);
        }
    };


    const changeOption = serviceId => {
        if(serviceId === 'showLead'){
            if(!showLeadOptions){
                setShowLeadOptions(true)
                props.typeOrderSelected(true);
            } 
            else {
                setShowLeadOptions(false)
                props.typeOrderSelected(false);
            }
        }
        if(serviceId === 'leadThree'){
            setIsSelected(true)
            props.typeOrder('urgency');
            props.typeOrderSelected(true);
            setTypeOrder('urgency');
            props.jobType('lead');
        }
        if(serviceId === 'leadOne'){
            setIsSelected(true)
            props.typeOrder('urgency');
            props.typeOrderSelected(true);
            setTypeOrder('urgency');
            props.jobType('leadOne');
        }
        if(serviceId === 'fixed'){
            setIsSelected(true)
            props.typeOrder('job/new/availability');
            props.typeOrderSelected(true);
            setTypeOrder('job/new/availability');
            props.jobType('fixed');
            setShowLeadOptions(false)
        }
    /*    console.log('serviceId => ', serviceId);
        setTypeOrder(serviceId);
        console.log('TypeOrder => ', typeOrder);*/
        //Remove urgency extra
        const tempArray = extrasArray;
        /*tempArray.map((item, index) => {
            if (item.type === "urgency") {
                let data = {
                    extraAmount: props.price.extraAmount - item.pricePerUnit
                }
                props.jobPriceExtra(data);
                tempArray.splice(index, 1);
            }
        });*/

        //Add urgency extra
       /* if (serviceId === "urgent") {
            const extraPrice = tempArray.reduce((accumulator, extra) => {
                if (extra.pricePerUnit >= 0) {
                    return accumulator + extra.pricePerUnit * 0.5 * extra.units;
                } else {
                    return accumulator;
                }
            }, 0);

            let urgencyPrice = 82.645;
            if (props.price + props.extraAmount < 200) {
                urgencyPrice = props.price * 0.5 + extraPrice;
            }

            tempArray.push({
                type: "urgency",
                name: "Incremento por servicio de urgencia",
                units: 1,
                pricePerUnit: urgencyPrice
            });
            let data = {
                extraAmount: props.price.extraAmount + urgencyPrice,
                extrasArray: tempArray
            }
            props.jobPriceExtra(data);
        }*/

        setExtrasArray(tempArray);
        setSelectedOption(serviceId);
    };

    return (
        <div id="service-price" className="section">
            <Nav />
            {/*<BreadCrumb history={props.history} location={props.location} />*/}
            <div className="container">
                <div className="row">
                    
                    {!job.transport && (
                        <div
                            className="col-md-offset-1 col-md-12"
                            style={{
                                opacity: selectedOption === "lead" ? 0.5 : 1
                            }}
                        >
                            <div className="add-extras">
                                {/*<h5 className="title">Extras del servicio</h5>*/}
                                <p/>
                                {/*extrasArray.length > 0 ? (
                                    <div className="extras-list">
                                        {/*extrasArray.map((extra, index) => {
                                            return (
                                                {<ExtraInfoCell
                                                    key={"extra" + index}
                                                    extra={extra}
                                                />}
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <p>No hay extras añadidos</p>
                                )*/}
                                {/*<div className="button-view">
                                    <Button
                                        onClick={() => setShowExtrasModal(true)}
                                        buttonText="Añadir extras"
                                    />
                                </div>*/}
                            </div>
                            <div className="includes">
                            <h4>Estás a punto de enviar una comunicación de incidencia para que ACR proceda a su revisión. Rogamos aportes toda la información y documentación posible para que el equipo ACR pueda valorar correctamente la incidencia.
                            </h4>
                            <h5>
                                Una vez revisado, ACR se pondrá en contacto contigo.
                            </h5> 
                            <h5>
                                Muchas gracias.
                            </h5> 
                            <h5>
                                ACR.
                            </h5> 
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {showExtrasModal && (
                {/*<ExtrasModal
                    isVisible={showExtrasModal}
                    onClose={() => setShowExtrasModal(false)}
                    extrasArray={extrasArray}
                    saveExtras={saveExtras}
                    allowDeleteItems={true}
                    subvertical={subvertical}
                    isUrgent={selectedOption === "urgent"}
                    basicPrice={job.price.amount}
                />*/
                }
            )}
            {
                isSelected && (
                    <StepControlls
                        history={props.history}
                        location={props.location}
                        page={props.step}
                        next={job.transport ? "job/new/date" : props.serviceType}
                        prev={job.transport ? "job/new/weight" : "job/new/location"}
                        nextTitle={"Siguiente"}
                        disabled={disabled && editPrice}
                        update={update}
                    />
                )
            }
            {
                !isSelected && (
                    <StepControlls
                        history={props.history}
                        location={props.location}
                        page={props.step}
                        next={job.transport ? "job/new/date" : props.serviceType}
                        prev={job.transport ? "job/new/weight" : "location"}
                        nextTitle={"Siguiente"}
                        disabled={true}
                        update={update}
                    />
                )
            }

        </div>
    );
}

const mapStateToProps = state => {
    return {
        job: state.jobReducer,
        price: state.jobReducer.price.amount,
        extraAmount: state.jobReducer.price.extraAmount,
        serviceType: state.serviceReducer.service.type
    }
}

const mapDispatchToProps = dispatch => {
    return {
        jobPriceExtra: (data) => dispatch(jobPriceExtra(data)),
        jobPriceSelected: (data) => dispatch(jobPriceSelected(data)),
        typeOrder: (type) => dispatch(typeOrder(type)),
        typeOrderSelected: (selected) => dispatch(typeOrderSelected(selected)),
        jobType: (type) => dispatch(jobType(type))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServicePrice);