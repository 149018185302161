import React, { useEffect, useState } from "react";
import "../styles/customSelect.css";

export default function CustomSelect(props) {
    const [selectedOption, setSelectedOption] = useState({})
    const [isLoaded, setIsLoaded] = useState(false)


    useEffect(()=>{
        setIsLoaded(true)
    },[props])
    

   const handleSelected=(option) => {
        setSelectedOption(option)
        props.selectedOption(option)
        props.setIsActive(props.id)
    }

    return (
        <div className={isLoaded ? "customSelect card loaded": "customSelect card"}>
            <div className="select">
                <span className="selectLabel" >{props.title}</span>
                <div className="selectWrapper">
                    <div className={props.isActive ? "isActive selectCustom js-selectCustom":"selectCustom js-selectCustom"}  >
                        <div className="selectCustom-trigger"  onClick={()=>props.setIsActive(props.id)}>{ !selectedOption  ? "Selecciona una opcion": selectedOption.formattedData}</div>
                        <div className="selectCustom-options">
                           {props.options.map((option)=>{
                                return(
                                    <div key={option.id} className="selectCustom-option" onClick={()=>{handleSelected(option)}}>{option.formattedData}</div>
                                )
                           })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

